<template>
  <div class="main">
    <a class="floaty" href="/sites"><img src="../assets/Sitemap.gif" alt="Sitemap"></a>
  </div>
</template>

<script>
export default {
  name: "SitemapButton"
}
</script>

<style scoped>
.main {
  position: fixed;
  bottom: 0%;
  right: 0;
  border: 5px ridge red;
  background-color: yellow;
  background-image: url("../assets/maptexture.png");
}

</style>