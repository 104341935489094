<template>
  <div>

    <img class="side" :src="require(`@/assets/${img}`)" alt = "member.png">
    <div class="side">
      <h3>{{name}}</h3>
      <p>{{desc}}</p>
    </div>


  </div>
</template>

<script>
export default {
  name: "MemberText",
  props: {
    name: String,
    img: String,
    desc: String
  }
}
</script>

<style scoped>
.side {
  display: inline-block
}

</style>