<template>
 <div id = main>
   <h2 style="color:blue; text-align: center;">Members</h2>
   <p> ===Under construction=== </p>
   <MemberText name="Placeholder" img="idiotsocto.png" desc="description"></MemberText>

 </div>
</template>

<script>
import MemberText from './MemberText.vue'
export default {
  name: "MembersComp",
  components: {
    MemberText
  }
}
</script>

<style scoped>

  #main {
    font-family: "Droid Sans";
    text-align: left;
    margin-left: 300px;
    margin-right: 300px;
    padding: 17px;
    padding-top: 10px;
    background-color: beige;
    height: 100%;
    border-right: double blueviolet 5px;
    border-left: double blueviolet 5px;
    border-bottom: double blueviolet 5px;
  }
</style>