<template>
  <div class="player" >
    <img v-bind:src="require(`@/assets/${image}`)" v-on:click="spawnEvent" height="300" width="100">
    <div v-on:mouseover="changeImage" >
      <audio src="//idiotson.top/musika/Octopus.mp3" controls autoplay loop ></audio>
    </div>
  </div>

</template>

<script>
export default {
  name: "ArrowComponent",
  data() {
    return {
      image: 'arrow.png'
    }

  },
  methods: {
    changeImage() {

      this.image ="empty.png";
    }
  }
}
</script>

<style scoped>
audio {

}
img {
  right: 0;

}
.player {

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: right;
  position: fixed;

  margin: 5px;

  bottom: 0;
  left: 0;
}
</style>