<template>

  <div class="egg">
    Hello <br>
    Who made you open this website? <br>
    :)
    <div class="quiz">
      <iframe name="opinionstage-widget" src="https://www.opinionstage.com/api/v1/widgets/1036022/iframe?em=1" data-opinionstage-iframe="5772af58-f13a-4feb-ad65-610b29ad2db7" width="500" height=100% scrolling="auto" style="border: none;" frameBorder="0" allow="fullscreen" webkitallowfullscreen="true" mozallowfullscreen="true" referrerpolicy="no-referrer-when-downgrade"></iframe>


    </div>

  </div>
</template>

<script>
export default {
  name: "FearComp"
}


</script>

<style scoped>
.egg {
  background-image: url("../assets/angellines.png");
  background-repeat: round;
  height: 100%;
  display: inline-block;
}
.quiz {
 height: 83%;
  width: 50%;
}
iframe {

}
</style>