<template>
  <div>
    <p> ===Under Construction===</p>

    <div>
      <img src="../assets/ilovemyonlinefriends.gif" alt="ilovemyonlinefriends.gif">
    </div>
    <div>
      <img src="../assets/idiotsmeetup.png" alt="idiotsmeetup.png" style="border: ridge hotpink 5px">
      <img src="../assets/troaa.png" alt="troaa.png" style="border: ridge hotpink 5px">
    </div>
    <div>
      <img src="../assets/idiotsontopwordart.png" alt="idiotsontopwordart.png" width="400" height="400">
    </div>
  </div>
</template>

<script>
export default {
  name: "GalleryComp"
}
</script>

<style scoped>
img {
  display: inline-block;
}


</style>