<template>
  <div id="app">
    <arrow></arrow>
    <LogoTitle></LogoTitle>
    <HelloWorld msg="Welcome to idiots"/>
    <Bar v-on:clicked="blabla" class="b"></Bar>
    <component :is="currentComponent" class="a"></component>
    <SitemapButton></SitemapButton>


  </div>

</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import LogoTitle from './components/LogoTitle.vue'
import Bar from './components/Bar.vue'
import Frontpage from './components/Frontpage.vue'
import Arrow from './components/Arrow.vue'
import SitemapButton from './components/SitemapButton.vue'

export default {
  name: 'App',
  data() {
    return {
      currentComponent: 'view-01'
    }
  },
  components: {
    HelloWorld,
    'view-01': Frontpage,

    LogoTitle,
    Bar,
    Arrow,
    SitemapButton
  },
  methods: {
    /*
     umm how do you write single line comments ?
     this function changes the main component on the site, making it look like you just went to another page
     */
    blabla: function(component) {
      this.currentComponent = component;
    },

   }
}
</script>

<style>
#app {


  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  padding: 0;
  height: 100%;
}
.a {
  height:50%;
}
SitemapButton {


}



</style>
