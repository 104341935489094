<template>
<div id="back">
  <div id="main">
    <div id="welcome">

      <h2>
       Welcome to the OFFICIAL idiots webpage !
      </h2>
      <p>
        >Who are we?
      </p>
      <ul class="help">
        <li> "idiots" is a group of people that like to spend time together! We are cool and we have swag.</li>
      </ul>
      <p>
        <br>
        >What is the purpose of this website?
      </p>
      <ul class="help">
        <li> This website serves as a peek into our community from the outside. It is also a convenient way to document
        important events and our history.</li>

      </ul>
      <h1 style="text-align: center"> Enjoy !</h1>

      <h3 class="news"> News: </h3>
      <p class="news">
        °12.09.2022: The idiots site now runs on HTTPS!!!!! Woohoo! <br> <br>
        °02.08.2022 (in the Evening): OKTOPPOSSES GARDDN3N IN THE SHADEEE <br> <br>
        °02.08.2022: The idiots member quiz has been released. :) <br> <br>
        °11.11.2021: The official idiots website is now online... again ! Removing the neocities site...<br>
        (haha funny date) <br> <br>
        °24.10.2021: The official idiots website is now online !
      </p>
    </div>

  </div>
</div>
</template>

<script>
export default {
  name: "FrontpageComp"
}
</script>

<style scoped>

  .help {
    color: gold;
  }
  .news {
    color: darkgreen;
  }
  #welcome {
    color: red;
    font-family: "Droid Sans";
  }
  #main {
    font-family: "Droid Sans";
    text-align: left;
    margin-left: 300px;
    margin-right: 300px;
    padding: 17px;
    padding-top: 10px;
    background-color: beige;
    height: 100%;
    border-right: double blueviolet 5px;
    border-left: double blueviolet 5px;
    border-bottom: double blueviolet 5px;
  }
  #back {
    height: 100%;
  }
  #octo {
    text-align: center;
  }

</style>