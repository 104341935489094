<template>
  <nav>
    <ul>
      <li @click="changeComponent(front)">
        Homepage
      </li>

      <li @click="changeComponent(members)">
        Members
      </li>
      <li @click="changeComponent(history)">
        History
      </li>
      <li @click="changeComponent(gallery)">
        Gallery
      </li>
      <li @click="changeComponent(fear)">
        Fearful
      </li>
    </ul>
  </nav>
</template>

<script>
  import Frontpage from './Frontpage.vue'
  import Members from './Members.vue'
  import History from './History.vue'
  import Gallery from './Gallery.vue'
  import Fear from './Fear.vue'
  export default {
    name: "BarComp",
    props: {
    },
    data() {
      return {
        front: Frontpage,
        members: Members,
        history: History,
        gallery: Gallery,
        fear: Fear,
      }
    },
    methods: {
      changeComponent: function(component) {
        this.$emit('clicked', component);

      }
    }
  }
  </script>

  <style scoped>
  ul{
    background-color: #42b983;
    width: 100%;

    display: table;
    padding: 0;
    margin: 0;
    border-top: groove blueviolet;
    border-bottom: groove blueviolet;
  }
  li {

    display: table-cell;
    border: groove hotpink;
    color: snow;
    font-family:"Courier 10 Pitch";
    margin: 0;
    padding: 0;

  }
  li:hover{
    color: darkmagenta;
    border-color: darkred;
  }
  nav {
    padding: 0;
    margin: 0;
  }
</style>