<template>
  <div id="image">




    <img src="../assets/idiots.png" alt="idiots.png">
  </div>
</template>

<script>

export default {
  name: "LogoTitle"
}
</script>

<style scoped>
#image {
  background-color: #42b983;
  font-size: xxx-large;
  border-top: double blueviolet 5px;
  border-right: double blueviolet 5px;
  border-left: double blueviolet 5px;
  padding-bottom: 0px;
  margin: 0;

}
</style>