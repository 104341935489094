<template>
  <div :style="{ backgroundColor: backgroundcolor }" id="wrapper">
    <div class="hello">
      <!-- <h1>{{ msg }}</h1> -->
      <img src="../assets/weareontop.gif" alt="idiotsontop.png">
   </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body, html {
  background-color: #42b983;


}
html {

}
body {

}
img {
  padding: 0;
  margin: 0;
}
#wrapper {

  margin: 0;
  padding: 0;
  background-color: blueviolet;
  width: 100%;  padding-top: 10px;
  padding-bottom: 10px;
  border-top: inset hotpink;

}
h1 {
  font-family: "Bitstream Vera Sans Mono";

}
h3 {
  margin: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0px;
}
a {
  color: #42b983;
}
</style>
